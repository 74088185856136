import { LearningEvent, SessionSourceType, SessionType } from 'src/api/learningSessions/types';
import { toCamelCaseDeep } from 'src/utils/transform';
import { SuccessResponse } from 'src/api/types';
import MemriseApi from '../MemriseApi';
import * as Types from './types';
import * as Schema from './schema';

export const toApiLearningEvent = ({
  boxTemplate,
  bonusPoints,
  scenarioId,
  timeSpent,
  givenAnswer,
  learningElement,
  definitionElement,
  testId,
  memId,
  createdDate,
  currentStreak,
  growthLevel,
  learnableId,
  nextDate,
  lastDate,
  notDifficult,
  totalStreak,
  ...rest
}: LearningEvent): Schema.LearningEvent => ({
  box_template: boxTemplate,
  bonus_points: bonusPoints,
  scenario_id: scenarioId,
  time_spent: timeSpent,
  given_answer: givenAnswer,
  learning_element: learningElement,
  definition_element: definitionElement,
  test_id: testId,
  mem_id: memId,
  created_date: createdDate,
  current_streak: currentStreak,
  growth_level: growthLevel,
  learnable_id: learnableId,
  next_date: nextDate,
  last_date: lastDate,
  not_difficult: notDifficult,
  total_streak: totalStreak,
  ...rest,
});

export default class MeApiClass extends MemriseApi {
  constructor() {
    super({ endpointPrefix: '/me' });
  }

  public async me(): Promise<Schema.MeResponse> {
    return this.get(`/`);
  }

  public async getUserLanguages(): Promise<Types.UserLanguagesResponse> {
    const response = await this.get<Schema.UserLanguagesResponse>(`/language_pairs/`);
    return toCamelCaseDeep(response);
  }

  public async getUserLanguageInfo(
    languagePairId: number,
  ): Promise<Types.UserLanguageInfoResponse> {
    const response = await this.get<Schema.UserLanguageInfoResponse>(
      `/language_pairs/${languagePairId}/`,
    );
    return toCamelCaseDeep(response);
  }

  public async getCurrentStatus({
    languagePairId,
  }: {
    languagePairId: number;
  }): Promise<Types.CurrentStatusResponse> {
    const response = await this.get<Schema.CurrentStatusResponse>(
      `/language_pairs/${languagePairId}/current_status/`,
    );
    return toCamelCaseDeep(response);
  }

  public async quitLanguagePair(languagePairId: number) {
    return this.delete(`/language_pairs/${languagePairId}/`, {});
  }

  public async getProgressResetStatus(languagePairId: number) {
    const response = this.get<Schema.CurrentStatusResponse>(
      `/language_pairs/${languagePairId}/progress_reset_status/`,
    );
    return toCamelCaseDeep(response);
  }

  public async postProgressRegister(
    languagePairId: number,
    learningEvents: LearningEvent[],
    sessionType: SessionType,
    sessionSourceType: SessionSourceType,
    sessionSourceId?: number,
  ): Promise<SuccessResponse> {
    return this.post(`/language_pairs/${languagePairId}/progress/register/`, {
      bodyObject: {
        events: learningEvents.map(toApiLearningEvent),
        session_type: sessionType,
        session_source_type: sessionSourceType,
        scenario_id: sessionSourceId,
      },
    });
  }

  public async getPractice(languagePairId: number): Promise<Types.PracticeResponse> {
    const response = await this.get<Schema.PracticeResponse>(
      `/language_pairs/${languagePairId}/practice/`,
    );
    return toCamelCaseDeep(response);
  }

  public async getLanguagePairImmerse(
    languagePairId: number,
  ): Promise<Types.LanguagePairImmerseResponse> {
    const response = await this.get<Schema.LanguagePairImmerseResponse>(
      `/language_pairs/${languagePairId}/immerse/`,
    );
    return toCamelCaseDeep(response);
  }

  public async reportImmerseError(
    languagePairId: number,
    contentMediaId: number,
    errorCode: Schema.VideoErrorCode,
  ): Promise<void> {
    try {
      await this.post<never, Schema.ImmerseErrorReportRequest>(
        `/language_pairs/${languagePairId}/immerse/${contentMediaId}/report_error/`,
        {
          bodyObject: {
            error: errorCode,
          },
        },
      );
    } catch (e) {
      // failures in error reporting are ignored
      return;
    }
  }

  public async convertClassicProgress(): Promise<Types.ProgressConversionResponse> {
    const response = await this.post<Schema.ProgressConversionResponse>(
      '/path_scenarios_beta/convert_classic_progress/',
      {},
    );
    return toCamelCaseDeep(response);
  }

  public async getTopicsAndTags(
    languagePairId: number,
    params: Schema.TopicsRequest,
  ): Promise<Types.TopicsResponse> {
    const response = await this.get<Schema.TopicsResponse, Schema.TopicsRequest>(
      `/language_pairs/${languagePairId}/topics_and_tags/`,
      params,
    );
    return toCamelCaseDeep(response);
  }

  public async getProgress(languagePairId: number): Promise<Types.ProgressResponse> {
    const response = await this.get<Schema.ProgressResponse>(
      `/language_pairs/${languagePairId}/progress/`,
    );
    return toCamelCaseDeep(response);
  }

  public async getPoints(languagePairId: number): Promise<Types.PointsResponse> {
    const response = await this.get<Schema.PointsResponse>(
      `/language_pairs/${languagePairId}/points/`,
    );
    return toCamelCaseDeep(response);
  }

  public async getRecommendations(languagePairId: number): Promise<Types.RecommendationsResponse> {
    const response = await this.get<Schema.RecommendationsResponse>(
      `/language_pairs/${languagePairId}/recommendations/`,
    );
    return toCamelCaseDeep(response);
  }

  public async getStats(
    languagePairId: number,
    params: Types.StatsRequest,
  ): Promise<Types.StatsResponse> {
    const response = await this.get<Schema.StatsResponse, Schema.StatsRequest>(
      `/language_pairs/${languagePairId}/stats/`,
      {
        start_date: params.startDate,
        end_date: params.endDate,
      },
    );
    return toCamelCaseDeep(response);
  }
}

export const MeApi = new MeApiClass();
