import {
  Learnable,
  Presentation,
  Screen,
  SessionType,
  TemplateName,
  Test,
  UserPath,
  UserScenario,
} from 'src/api/learningSessions/types';
import { toCamelCaseDeep } from 'src/utils/transform';
import * as Schema from './schema';
import { LearningSessionsDomainResponse, LearningSessionsEndDomainResponse } from '.';

export const toApiSessionType = (sessionType: SessionType): string => {
  if (sessionType === SessionType.AUDIO) return 'audio';
  if (sessionType === SessionType.REVIEW) return 'review';
  return sessionType;
};

export const fromApiLearningSessionResponse = ({
  learnables,
  progress,
  session_source_info,
  settings,
}: Schema.LearningSessionsResponse): LearningSessionsDomainResponse => ({
  learnables: learnables.map(fromApiLearnable),
  progress: progress.map(toCamelCaseDeep),
  sessionSourceInfo: {
    numDueForReview: session_source_info.num_due_for_review,
    levelName: session_source_info.level_name,
    levelId: session_source_info.level_id,
    name: session_source_info.name,
    sourceType: session_source_info.source_type,
    sourceId: session_source_info.source_id,
    templateId: session_source_info.template_id,
    languagePairId: session_source_info.language_pair_id,
    parentSourceId: session_source_info.parent_source_id ?? undefined,
  },
  settings: {
    disableMultimedia: settings.disable_multimedia,
    disableTapping: settings.disable_tapping,
    disableTyping: settings.disable_typing,
    prioritizeTyping: settings.prioritize_typing,
  },
});

export const fromApiLearnable = ({
  learning_element,
  definition_element,
  learning_element_tokens,
  definition_element_tokens,
  item_type,
  screen_config,
  screens,
  ...rest
}: Schema.Learnable): Learnable => ({
  learningElement: learning_element,
  definitionElement: definition_element,
  learningElementTokens: learning_element_tokens,
  definitionElementTokens: definition_element_tokens,
  itemType: item_type,
  screens: fromApiScreens(screens),
  screenConfig: {
    explore: screen_config.explore.map(({ screen_id }) => ({
      screenId: screen_id,
    })),
    growthLevel0: screen_config.growth_level_0.map(({ screen_id }) => ({
      screenId: screen_id,
    })),
    growthLevel1: screen_config.growth_level_1.map(({ screen_id }) => ({
      screenId: screen_id,
    })),
    growthLevel2: screen_config.growth_level_2.map(({ screen_id }) => ({
      screenId: screen_id,
    })),
    growthLevel3: screen_config.growth_level_3.map(({ screen_id }) => ({
      screenId: screen_id,
    })),
    growthLevel4: screen_config.growth_level_4.map(({ screen_id }) => ({
      screenId: screen_id,
    })),
    growthLevel5: screen_config.growth_level_5.map(({ screen_id }) => ({
      screenId: screen_id,
    })),
  },
  ...rest,
});

export const fromApiScreens = (screens: Schema.Learnable['screens']): Screen[] =>
  Object.entries(screens)
    .filter(([, screen]) => !isGrammarScreen(screen))
    .map(([, screen]) => screen) // drop object number key
    .map(screen => {
      if (isPresentationScreen(screen)) {
        return fromApiPresentationScreen(screen);
      }

      /* istanbul ignore else: internal consistency check */
      if (isTestScreen(screen)) {
        return fromApiTestScreen(screen);
      }

      /* istanbul ignore next: internal consistency check */
      throw new Error(`Tried to convert a screen that was neither a presentation or test screen`);
    });

export const isGrammarScreen = (
  screen: Schema.Screen,
): screen is Schema.GrammarExamples | Schema.GrammarTip =>
  Object.prototype.hasOwnProperty.call(screen, 'examples');

export const isPresentationScreen = (screen: Schema.Screen): screen is Schema.Presentation =>
  screen.template === Schema.TemplateName.PRESENTATION;

const fromApiPresentationScreen = ({
  visible_info,
  hidden_info,
  ...rest
}: Schema.Presentation): Presentation => ({
  visibleInfo: visible_info,
  hiddenInfo: hidden_info,
  ...rest,
});

export const isTestScreen = (x: Schema.Screen): x is Schema.Test =>
  x.template !== Schema.TemplateName.PRESENTATION;

export const fromApiTestScreen = ({
  post_answer_info,
  feedback_screen,
  is_strict,
  translation_prompt,
  gap_prompt,
  template,
  ...rest
}: Schema.Test): Test => ({
  postAnswerInfo: post_answer_info,
  feedbackScreen: feedback_screen === null ? null : fromApiPresentationScreen(feedback_screen),
  isStrict: is_strict,
  translationPrompt: translation_prompt,
  gapPrompt: gap_prompt,
  template: template as Exclude<TemplateName, TemplateName.PRESENTATION>,
  ...rest,
});

const fromApiUserPath = (userPath: Schema.UserPath): UserPath => {
  return {
    name: userPath.name,
    totalItemsLearned: userPath.num_learnables_learned,
  };
};

const fromApiUserScenario = (userScenario: Schema.UserScenario): UserScenario => {
  return {
    userScenarioId: userScenario.user_scenario_id,
    name: userScenario.name,
    numThings: userScenario.num_learnables,
    percentComplete: userScenario.percent_complete,
    itemsLearned: userScenario.num_learnables_learned,
    iconUrl: userScenario.icon_url,
    isPremium: userScenario.is_premium,
  };
};

export const fromApiLearningSessionEndResponse = ({
  summary_stats,
  next_session,
}: Schema.LearningSessionsEndResponse): LearningSessionsEndDomainResponse => ({
  summaryStats: {
    userPath: summary_stats.user_path ? fromApiUserPath(summary_stats.user_path) : null,
    userScenario: summary_stats.user_scenario
      ? fromApiUserScenario(summary_stats.user_scenario)
      : null,
  },
  nextSession: {
    singleContinue: next_session.single_continue
      ? {
          url: next_session.single_continue.url,
        }
      : undefined,
  },
});
