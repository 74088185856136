import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { FC, ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { ZENDESK } from 'src/constants/links.const';
import { ROUTES } from 'src/constants/routes.const';
import { BackHeader, ErrorModal, LinkButton, Page, Stack } from '..';
import * as S from './styles';

const msgs = defineMessages({
  pageTitle: { id: 'error_page_title' },
  whoops: {
    id: 'learning_session_error_generic_header',
  },
  goToDashboard: { id: 'learning_session_error_button_dashboard' },
  reportBug: { id: 'learning_session_error_button_report_bug' },
});

interface IProps {
  title?: string;
  detail?: string;
  buttonHref?: string;
  buttonText?: string;
  onDismiss?: () => void;
  children?: ReactNode;
}

export const FullPageError: FC<IProps> = ({
  title,
  detail,
  buttonHref = ROUTES.dashboard,
  buttonText,
  onDismiss = () => {},
  children,
}) => {
  const intl = useIntl();
  const { asPath, reload } = useRouter();
  return (
    <Page footerStyle="none" header={<BackHeader />}>
      <Head>
        <title>{intl.formatMessage(msgs.pageTitle)}</title>
      </Head>

      <ErrorModal errorTitle={title ?? intl.formatMessage(msgs.whoops)} errorDetail={detail} isOpen>
        <S.Content>{children}</S.Content>
        <Stack $spacing="s4" $direction="horizontal" $align="center" $shouldWrap>
          <LinkButton
            href={buttonHref}
            buttonSize="small"
            buttonType="primary"
            onClick={e => {
              onDismiss();
              // Handles the case where the link points to the current page
              if (buttonHref !== asPath) return;
              e.preventDefault();
              reload();
            }}
          >
            {buttonText || intl.formatMessage(msgs.goToDashboard)}
          </LinkButton>
          <a href={ZENDESK}>{intl.formatMessage(msgs.reportBug)}</a>
        </Stack>
      </ErrorModal>
    </Page>
  );
};
