import { serialize, parse, SerializeOptions } from 'cookie';
import { isClientSide } from 'src/utils/env/isClientSide';
import { ONE_DAY_IN_SECONDS } from '../constants/times.const';

/**
 * Set cookies client side only
 */
// istanbul ignore next: we can't test browser cookies in unit tests
export const setClientCookie = (name: string, value: string, options?: SerializeOptions) => {
  if (!isClientSide()) return;

  document.cookie = serialize(name, value, { path: '/', ...options });
};

type ObjectToCookies = (cookiesObj: Record<string, unknown>) => string;
export const objectToCookie: ObjectToCookies = cookiesObj =>
  Object.entries(cookiesObj)
    .map(v => v.join('='))
    .join('; ');

type RequestWithCookies = { cookies?: Record<string, string> };

/**
 * Get cookies client side or server side
 */
export const getCookies = (req?: RequestWithCookies): Record<string, string | undefined> =>
  req && req.cookies
    ? req.cookies
    : /* istanbul ignore next: untested branch of code, please test */ parse(document.cookie);

/* istanbul ignore next: this function only exists to mock in tests anyway,
 * we can't test getting browser cookies in unit tests */
export const getCookie = (name: string, req?: RequestWithCookies): string | undefined =>
  getCookies(req)[name];

/**
 * Returns true if the browser has cookies enabled, false if not
 * Defaults to true for SSR
 */
export const checkCookiesAreEnabled = (): boolean =>
  typeof window === 'undefined' || window.navigator.cookieEnabled;

export function useCookiesAreEnabled(): boolean {
  return checkCookiesAreEnabled();
}

// Chrome limits cookie max age to 400 days and recommends using it
export const LONG_TERM_COOKIE_MAX_AGE = 400 * ONE_DAY_IN_SECONDS;

export function extendLongTermClientCookie(name: string) {
  const value = getCookie(name);

  if (value) {
    setClientCookie(name, value, { maxAge: LONG_TERM_COOKIE_MAX_AGE });
  }
}
