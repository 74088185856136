import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import ReactModal, { Props as ReactModalProps } from 'react-modal';
import { addCustomScrollbar } from 'src/utils/addCustomScrollbar';
import { Icon } from '..';

/**
 * Flips around the classNames so that styled-components can
 * style the top-most element in the tree and we know the
 * the names of the content and the overlay classNames
 *
 * Now we get this order (instead of className going on Content)
 *
 * <Portal> <- where className goes
 *  <Overlay> <- where overlayClassName goes
 *    <Content /> <- where reacstModalContent goes
 *  </Overlay>
 * </Portal>
 */
const ReactModalStylesAdapter = ({
  className,
  ...props
}: ReactModalProps & { children: ReactNode }) => (
  <ReactModal
    className="reactModalContent"
    overlayClassName="reactModalOverlay"
    portalClassName={className as string}
    {...props}
  />
);

export const CloseIcon = styled(Icon)<{ $darkCloseIcon: boolean }>(
  ({ theme, $darkCloseIcon }) => css`
    /* optically align */
    cursor: pointer;
    position: relative;
    z-index: ${theme.zi.modal};
    font-size: ${theme.sz.s8};

    svg {
      filter: 'none';
    }

    svg path {
      fill: ${$darkCloseIcon ? theme.co.secondary20 : theme.co.primary99};
    }
  `,
);

export const CloseButton = styled.button(
  ({ theme }) => css`
    background: none;
    border-radius: ${theme.bo.round};
    border: none;
    top: ${theme.sz.s2};
    right: ${theme.sz.s2};
    box-sizing: content-box;
    padding: ${theme.sz.s2};
    position: absolute;
    z-index: ${theme.zi.modal};

    &:focus {
      box-shadow: 0 0 0 3px ${({ theme }) => theme.co.information50};
    }
  `,
);

export const CloseWrapper = styled.div(
  ({ theme }) => css`
    position: sticky;
    z-index: ${theme.zi.z1};
    top: ${theme.sz.s0};
    width: 100%;
    padding: ${theme.sz.s3} ${theme.sz.s0} ${theme.sz.s10};
    background: ${theme.co.neutral99};
    text-align-last: center;
    border-radius: ${theme.sz.s4};
  `,
);

/* istanbul ignore next: tested visually */
export const Modal = styled(ReactModalStylesAdapter)<{
  $backgroundColor?: 'white' | 'primary99';
  $fullSize?: boolean;
}>(
  ({ theme, $backgroundColor, $fullSize }) => css`
    position: fixed;
    z-index: ${theme.zi.modal};
    top: 0;

    .reactModalOverlay {
      background: ${transparentize(0.2, theme.co.secondary20)};
      position: absolute;
      width: 100vw;
      height: 100dvh;
      display: flex;
      align-items: center;
      justify-content: center;

      ${$fullSize &&
      css`
        padding: 0;
      `}
    }

    .reactModalContent:focus {
      box-shadow: none;
    }

    .reactModalContent {
      margin: 0 ${theme.sz.s2};
      position: relative;
      background: ${$backgroundColor === 'white' ? theme.co.neutral99 : theme.co.neutral98};
      max-width: ${theme.sz.ms};
      width: 100%;
      height: fit-content;
      max-height: 90dvh;
      border-radius: ${theme.bo.medium};
      overflow: auto;
      ${addCustomScrollbar(theme)};

      ${$fullSize &&
      css`
        width: 100vw;
        height: 100dvh;
        max-width: 100vw;
        max-height: 100dvh;
        margin: 0;
        border-radius: 0;
      `}
    }
  `,
);
