import UAParser from 'ua-parser-js';
import { v4 as uuidv4 } from 'uuid';
import { isServerSide } from 'src/utils/env/isServerSide';

const UNKNOWN = 'unknown';

export const buildAnalyticsHeaders = (): Record<string, string> => {
  if (isServerSide()) return {};

  const uaResult = new UAParser().getResult();
  const clientType = getClientType();

  return {
    'x-correlation-id': uuidv4(),
    'x-client-type': clientType,
    'x-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    'x-os': normalizeOsName(uaResult.os.name),
    'x-os-version': uaResult.os.version || UNKNOWN,
    'x-device-type': normalizeDeviceType(uaResult.device.type),
    ...(clientType === 'web'
      ? {
          'x-browser': uaResult.browser.name || UNKNOWN,
          'x-browser-version': uaResult.browser.version || UNKNOWN,
        }
      : {}),
  };
};

function getClientType() {
  const params = new URLSearchParams(window.location.search);
  const client = params.get('client');
  return client && ['ios', 'android'].includes(client) ? client : 'web';
}

function normalizeOsName(name: string | undefined): string {
  if (!name) return UNKNOWN;

  return name.toLocaleLowerCase().replace(/\s/g, '_');
}

function normalizeDeviceType(type: string | undefined): string {
  // 'desktop' is not a possible device type, so treat all undefined values to be desktop
  // For more info see: https://github.com/faisalman/ua-parser-js/issues/182
  if (!type) return 'desktop';

  if (type === 'smarttv') return 'smart_tv';

  return type;
}
