import styled, { css, DefaultTheme } from 'styled-components';
import IconComponent from '../Icon';
import Loader from '../Loader';
import { ButtonSize, ButtonType, ButtonTypes } from './index';

const ButtonProps = (theme: DefaultTheme) => ({
  border: {
    [ButtonTypes.LIGHT_BLUE]: theme.co.information80,
    [ButtonTypes.PRIMARY]: 'none',
    [ButtonTypes.PRIMARY_PRO]: 'none',
    [ButtonTypes.PRIMARY_ALT]: 'none',
    [ButtonTypes.SECONDARY]: theme.co.secondary60,
    [ButtonTypes.SECONDARY_DARK]: theme.co.primary20,
    [ButtonTypes.TERTIARY]: 'none',
    [ButtonTypes.TERTIARY_ALT]: 'none',
  },
  borderWidth: {
    [ButtonTypes.LIGHT_BLUE]: theme.bo.mediumWidth,
    [ButtonTypes.PRIMARY]: 'none',
    [ButtonTypes.PRIMARY_PRO]: 'none',
    [ButtonTypes.PRIMARY_ALT]: 'none',
    [ButtonTypes.SECONDARY]: theme.bo.thinWidth,
    [ButtonTypes.SECONDARY_DARK]: theme.bo.thinWidth,
    [ButtonTypes.TERTIARY]: 'none',
    [ButtonTypes.TERTIARY_ALT]: 'none',
  },
  borderDisabled: {
    [ButtonTypes.LIGHT_BLUE]: theme.co.information80,
    [ButtonTypes.PRIMARY]: 'none',
    [ButtonTypes.PRIMARY_PRO]: 'none',
    [ButtonTypes.PRIMARY_ALT]: 'none',
    [ButtonTypes.SECONDARY]: 'none',
    [ButtonTypes.SECONDARY_DARK]: 'none',
    [ButtonTypes.TERTIARY]: 'none',
    [ButtonTypes.TERTIARY_ALT]: 'none',
  },
  shadow: {
    [ButtonTypes.LIGHT_BLUE]: theme.co.information80,
    [ButtonTypes.PRIMARY]: theme.co.primary0,
    [ButtonTypes.PRIMARY_PRO]: theme.co.primary0,
    [ButtonTypes.PRIMARY_ALT]: theme.co.secondary0,
    [ButtonTypes.SECONDARY]: 'none',
    [ButtonTypes.SECONDARY_DARK]: 'none',
    [ButtonTypes.TERTIARY]: 'none',
    [ButtonTypes.TERTIARY_ALT]: 'none',
  },
  background: {
    [ButtonTypes.LIGHT_BLUE]: theme.co.information98,
    [ButtonTypes.PRIMARY]: theme.co.primary20,
    [ButtonTypes.PRIMARY_PRO]: `linear-gradient(180deg, ${theme.co.primary20} 43.92%, ${theme.co.warning50} 100%);`,
    [ButtonTypes.PRIMARY_ALT]: theme.co.secondary20,
    [ButtonTypes.SECONDARY]: theme.co.neutral99,
    [ButtonTypes.SECONDARY_DARK]: theme.co.secondary20,
    [ButtonTypes.TERTIARY]: 'none',
    [ButtonTypes.TERTIARY_ALT]: 'none',
  },
  backgroundHoverAndFocus: {
    // focus states have a default browser outline (depending on the browser) but ALSO share hover states for more visibility
    [ButtonTypes.LIGHT_BLUE]: theme.co.information98,
    [ButtonTypes.PRIMARY]: theme.co.primary40,
    [ButtonTypes.PRIMARY_PRO]: theme.co.primary40,
    [ButtonTypes.PRIMARY_ALT]: theme.co.secondary30,
    [ButtonTypes.SECONDARY]: theme.co.neutral99,
    [ButtonTypes.SECONDARY_DARK]: theme.co.secondary20,
    [ButtonTypes.TERTIARY]: 'none',
    [ButtonTypes.TERTIARY_ALT]: 'none',
  },
  backgroundActive: {
    [ButtonTypes.LIGHT_BLUE]: theme.co.information80,
    [ButtonTypes.PRIMARY]: theme.co.primary0,
    [ButtonTypes.PRIMARY_PRO]: theme.co.primary0,
    [ButtonTypes.PRIMARY_ALT]: theme.co.secondary0,
    [ButtonTypes.SECONDARY]: theme.co.neutral99,
    [ButtonTypes.SECONDARY_DARK]: theme.co.secondary20,
    [ButtonTypes.TERTIARY]: 'none',
    [ButtonTypes.TERTIARY_ALT]: 'none',
  },
  backgroundDisabled: {
    [ButtonTypes.LIGHT_BLUE]: 'none',
    [ButtonTypes.PRIMARY]: theme.co.neutral95,
    [ButtonTypes.PRIMARY_PRO]: theme.co.neutral95,
    [ButtonTypes.PRIMARY_ALT]: theme.co.secondary20,
    [ButtonTypes.SECONDARY]: theme.co.neutral95,
    [ButtonTypes.SECONDARY_DARK]: theme.co.secondary50,
    [ButtonTypes.TERTIARY]: 'none',
    [ButtonTypes.TERTIARY_ALT]: 'none',
  },
  color: {
    [ButtonTypes.LIGHT_BLUE]: theme.co.secondary50,
    [ButtonTypes.PRIMARY]: theme.co.secondary0,
    [ButtonTypes.PRIMARY_PRO]: theme.co.secondary0,
    [ButtonTypes.PRIMARY_ALT]: theme.co.primary20,
    [ButtonTypes.SECONDARY]: theme.co.secondary0,
    [ButtonTypes.SECONDARY_DARK]: theme.co.primary20,
    [ButtonTypes.TERTIARY]: theme.co.secondary0,
    [ButtonTypes.TERTIARY_ALT]: theme.co.neutral99,
  },
  colorHoverAndFocus: {
    [ButtonTypes.LIGHT_BLUE]: ``,
    [ButtonTypes.PRIMARY]: ``,
    [ButtonTypes.PRIMARY_PRO]: ``,
    [ButtonTypes.PRIMARY_ALT]: ``,
    [ButtonTypes.SECONDARY]: theme.co.secondary50,
    [ButtonTypes.SECONDARY_DARK]: theme.co.primary40,
    [ButtonTypes.TERTIARY]: theme.co.secondary50,
    [ButtonTypes.TERTIARY_ALT]: theme.co.secondary60,
  },
  colorActive: {
    [ButtonTypes.LIGHT_BLUE]: theme.co.secondary50,
    [ButtonTypes.PRIMARY]: theme.co.secondary0,
    [ButtonTypes.PRIMARY_PRO]: theme.co.secondary0,
    [ButtonTypes.PRIMARY_ALT]: theme.co.primary20,
    [ButtonTypes.SECONDARY]: theme.co.secondary50,
    [ButtonTypes.SECONDARY_DARK]: theme.co.primary0,
    [ButtonTypes.TERTIARY]: theme.co.secondary50,
    [ButtonTypes.TERTIARY_ALT]: theme.co.neutral99,
  },
});

const getHeight = (buttonSize: ButtonSize, theme: DefaultTheme) => {
  switch (buttonSize) {
    case 'extraSmall':
      return theme.sz.s7;
    case 'small':
      return theme.sz.s8;
    case 'medium':
      return theme.sz.s11;
    case 'large':
      return theme.sz.s12;
  }
};

interface Props {
  $disabled?: boolean;
  $wide?: boolean;
  $buttonType: ButtonType;
  $buttonSize: ButtonSize;
  $noWrap?: boolean;
}

const buttonCss = css<Props>`
  z-index: ${({ theme }) => theme.zi.z2};
  position: relative;
  border: none;
  min-height: ${({ theme, $buttonSize }) => getHeight($buttonSize, theme)};
  padding: 0 ${({ theme }) => theme.sz.s4};

  border-radius: ${({ theme, $buttonSize }) =>
    $buttonSize === 'small' ? theme.bo.mediumSmall : theme.bo.medium};
  border: ${({ theme, $buttonType }) => ButtonProps(theme).borderWidth[$buttonType]} solid
    ${({ theme, $disabled, $buttonType }) =>
      $disabled
        ? ButtonProps(theme).borderDisabled[$buttonType]
        : ButtonProps(theme).border[$buttonType]};

  font-family: ${({ theme }) => theme.DEPRECATED_ty.openSans};
  font-size: ${({ theme }) => theme.DEPRECATED_ty.medium};
  font-weight: ${({ theme }) => theme.DEPRECATED_ty.bold};
  text-decoration: none;

  background: ${({ theme, $disabled, $buttonType }) =>
    $disabled
      ? ButtonProps(theme).backgroundDisabled[$buttonType]
      : ButtonProps(theme).background[$buttonType]};
  color: ${({ theme, $disabled, $buttonType }) =>
    $disabled ? theme.co.secondary50 : ButtonProps(theme).color[$buttonType]};
  box-shadow: ${({ theme, $buttonType, $disabled }) =>
    ButtonProps(theme).shadow[$buttonType] !== 'none' && !$disabled
      ? `0 ${theme.sz.s1} 0 ${ButtonProps(theme).shadow[$buttonType]}`
      : 'none'};

  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};

  width: ${({ $wide }) => ($wide ? '100%' : 'auto')};
  display: ${({ $wide }) => ($wide ? 'flex' : 'inline-flex')};
  align-items: center;
  justify-content: space-between;
  text-align: center;
  white-space: ${({ $noWrap }) => $noWrap && 'nowrap'};

  & > *:only-child {
    margin: auto;
  }

  &:hover,
  &:focus-visible {
    color: ${({ theme, $disabled, $buttonType }) =>
      !$disabled && ButtonProps(theme).colorHoverAndFocus[$buttonType]};
    background: ${({ theme, $disabled, $buttonType }) =>
      !$disabled && ButtonProps(theme).backgroundHoverAndFocus[$buttonType]};
  }

  &:active {
    background: ${({ theme, $buttonType }) => ButtonProps(theme).backgroundActive[$buttonType]};
    outline: none;
    box-shadow: ${({ theme, $buttonType, $disabled }) =>
      ButtonProps(theme).shadow[$buttonType] !== 'none' && !$disabled
        ? `0 ${theme.sz.s1} 0 ${ButtonProps(theme).shadow[$buttonType]}`
        : 'none'};
    color: ${({ theme, $disabled, $buttonType }) =>
      !$disabled && ButtonProps(theme).colorActive[$buttonType]};
  }
`;

export const Button = styled.button<Props>`
  ${buttonCss}
`;
export const A = styled.a<Props>`
  ${buttonCss}
`;

export const Content = styled.div<{ $isLeft: boolean; $loading?: boolean }>(
  ({ theme, $isLeft, $loading }) => css`
    ${$loading && `visibility: hidden`};

    ${$isLeft
      ? `float: left;
         flex-grow: 1;
         padding-left: ${theme.sz.s3};`
      : `justify-content: center;`};
  `,
);

export const Icon = styled(IconComponent)<{
  $disabled?: boolean;
  $buttonType: ButtonType;
}>(
  ({ theme }) => css`
    float: left;
    font-size: ${theme.sz.s8};
  `,
);

export const ButtonLoader = styled(Loader)`
  height: ${props => props.theme.sz.s6};
  margin-right: ${props => props.theme.sz.s4};
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
`;

export const ButtonContainer = styled.div``;

export const ErrorContainer = styled.div(
  ({ theme }) => css`
    position: relative;
    bottom: ${theme.sz.s4};
    top: ${theme.borderRadius};
    display: flex;
    width: 100%;
    z-index: ${theme.zi.z1};
  `,
);

export const Error = styled.span(
  ({ theme }) => css`
    width: 100%;
    background-color: ${theme.co.error60};
    text-align: center;
    color: ${theme.co.neutral99};
    border-bottom-right-radius: ${theme.bo.small};
    border-bottom-left-radius: ${theme.bo.small};
    padding: ${theme.sz.s4} ${theme.sz.s1} ${theme.sz.s1} ${theme.sz.s1};

    ${theme.typography.heading.mobile.xxxs};
  `,
);
