import {
  Learnable,
  LearnableProgress,
  NextSession,
  SessionSourceInfo,
  SessionSourceType,
  SessionType,
  SummaryStats,
  TestSettings,
} from 'src/api/learningSessions/types';
import MemriseApi from '../MemriseApi';
import {
  fromApiLearningSessionEndResponse,
  fromApiLearningSessionResponse,
  toApiSessionType,
} from './conversion';
import * as Schema from './schema';

/**
 * The response from /learning_sessions/:session_type, converted
 * for the domain types of web_client
 */
export interface LearningSessionsDomainResponse {
  learnables: Learnable[];
  progress: LearnableProgress[];
  sessionSourceInfo: SessionSourceInfo;
  settings: TestSettings;
}

/**
 * The response from /learning_sessions/:session_type, converted
 * for the domain types of web_client
 */
export interface LearningSessionsEndDomainResponse {
  summaryStats: SummaryStats;
  nextSession: NextSession;
}

class LearningSessionsApiClass extends MemriseApi {
  constructor() {
    super({ endpointPrefix: '/learning_sessions' });
  }

  public async startLearningSession(
    sessionType: SessionType,
    {
      sessionSourceType,
      sessionSourceId,
    }: {
      sessionSourceType: SessionSourceType;
      sessionSourceId: number;
    },
  ): Promise<LearningSessionsDomainResponse> {
    const apiSessionType = toApiSessionType(sessionType);

    const snakeParams = {
      session_source_id: sessionSourceId,
      session_source_type: sessionSourceType,
    };

    const res = await this.post<Schema.LearningSessionsResponse, Schema.LearningSessionParams>(
      `/${apiSessionType}/`,
      {
        bodyObject: snakeParams,
      },
    );

    return fromApiLearningSessionResponse(res);
  }

  public async end(
    sessionType: SessionType,
    sessionSourceType: SessionSourceType,
    sessionPoints: number,
    {
      sessionSourceId,
    }: {
      sessionSourceId: number;
    },
  ): Promise<LearningSessionsEndDomainResponse> {
    const apiSessionType = toApiSessionType(sessionType);

    const snakeParams = {
      session_points: sessionPoints,
      session_type: apiSessionType,
      session_source_type: sessionSourceType,
      session_source_id: sessionSourceId,
    };

    const res = await this.post<
      Schema.LearningSessionsEndResponse,
      Schema.LearningSessionsEndParams
    >(`/end/`, {
      bodyObject: snakeParams,
    });

    return fromApiLearningSessionEndResponse(res);
  }
}

export const LearningSessionsApi = new LearningSessionsApiClass();
