import { getLocalStorageEntry, setLocalStorageEntry } from 'src/utils/webStorageUtils';
import { SOURCE_LANGUAGES_LOCALES_MAP } from 'src/constants/locales.const';
import {
  getCookie,
  LONG_TERM_COOKIE_MAX_AGE,
  setClientCookie,
  extendLongTermClientCookie,
} from 'src/cookies';
import { reloadPage } from 'src/utils/navigation';

export const LANGUAGE_PAIR_ID = 'LANGUAGE-PAIR-ID';
export const SOURCE_LANGUAGE = 'SOURCE_LANGUAGE';
export const TARGET_LANGUAGE = 'TARGET_LANGUAGE';

export const saveSourceLanguage = (source: string, forbidReload?: boolean) => {
  const prevSource = getLocalStorageEntry(SOURCE_LANGUAGE);
  if (source !== prevSource) {
    setLocalStorageEntry(SOURCE_LANGUAGE, source);
  }

  const prevCookie = getCookie('memrise_lang');
  extendLongTermClientCookie('memrise_lang');

  const newCookie = SOURCE_LANGUAGES_LOCALES_MAP[source];

  if (prevCookie !== newCookie) {
    setClientCookie('memrise_lang', newCookie, { maxAge: LONG_TERM_COOKIE_MAX_AGE });

    if (!forbidReload) {
      reloadPage();
    }
  }
};

export const saveLastLanguagePair = (
  languagePairId: number,
  source?: string | null,
  target?: string | null,
  forbidReload?: boolean,
) => {
  setLocalStorageEntry(LANGUAGE_PAIR_ID, String(languagePairId));
  if (source) {
    saveSourceLanguage(source, forbidReload);
  }
  target && setLocalStorageEntry(TARGET_LANGUAGE, target);
};
