import styled, { css } from 'styled-components';

export const Root = styled.span(
  () => css`
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    display: inline-flex;
    vertical-align: middle;

    & svg {
      height: 1em;
      line-height: inherit;
      display: inline-flex;
      align-items: center;
      width: 1em;
    }
  `,
);
