import React, { ComponentType, forwardRef, HTMLProps, ReactNode, Ref } from 'react';
import { IconName } from '../index';
import Icons from '../Icon/icons';
import * as S from './styles';

type IconSet = { [key: string]: ComponentType };

export const ButtonTypes = {
  PRIMARY: 'primary',
  PRIMARY_ALT: 'primary-alt',
  PRIMARY_PRO: 'primary-pro',
  SECONDARY: 'secondary',
  SECONDARY_DARK: 'secondary-dark',
  TERTIARY: 'tertiary',
  TERTIARY_ALT: 'tertiary-alt',
  LIGHT_BLUE: 'light-blue',
} as const;

export const ButtonSizes = {
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small',
  EXTRA_SMALL: 'extraSmall',
} as const;

export type ButtonType = (typeof ButtonTypes)[keyof typeof ButtonTypes];

export type ButtonSize = (typeof ButtonSizes)[keyof typeof ButtonSizes];

export interface BaseButtonProps {
  a11yText?: string;
  buttonType?: ButtonType;
  buttonSize?: ButtonSize;
  className?: string;
  'data-testid'?: string;
  disabled?: boolean;
  /** The content to show below the button as an error message */
  error?: TranslatedNode;
  icon?: IconName | ReactNode;
  loading?: boolean;
  /** Prevents text from wrapping into multiple lines */
  noWrap?: boolean;
  type?: 'button' | 'submit' | 'reset';
  wide?: boolean;
}

function isIconName(value: string | ReactNode): value is IconName {
  const iconSet: IconSet = Icons;
  return typeof value === 'string' && iconSet[value] !== undefined;
}

type ButtonProps = BaseButtonProps &
  Pick<HTMLProps<HTMLButtonElement>, 'children' | 'onClick' | 'onKeyUp'>;

export const Button = forwardRef(
  (
    {
      a11yText,
      buttonType = 'primary',
      buttonSize = 'small',
      children,
      className,
      ['data-testid']: dataTestId,
      disabled,
      error,
      icon,
      loading,
      noWrap,
      onClick,
      onKeyUp,
      type,
      wide = false,
    }: ButtonProps,
    ref?: Ref<HTMLButtonElement>,
  ) => {
    return (
      <S.ButtonContainer>
        <S.Button
          $buttonSize={buttonSize}
          $buttonType={buttonType}
          $disabled={disabled}
          $wide={wide}
          ref={ref}
          onClick={loading || disabled ? undefined : onClick}
          onKeyUp={onKeyUp}
          aria-label={a11yText}
          title={a11yText}
          $noWrap={noWrap}
          className={className}
          data-testid={dataTestId ?? 'button'}
          type={type || 'button'}
        >
          {loading && <S.ButtonLoader />}
          {isIconName(icon) ? (
            <S.Icon $disabled={disabled} name={icon} $buttonType={buttonType} />
          ) : (
            icon
          )}
          {children && (
            <S.Content $isLeft={!!icon} $loading={loading}>
              {children}
            </S.Content>
          )}
        </S.Button>
        {error && (
          <S.ErrorContainer>
            <S.Error data-testid="btn-error">{error}</S.Error>
          </S.ErrorContainer>
        )}
      </S.ButtonContainer>
    );
  },
);

Button.displayName = 'Button';
export default isIconName;
