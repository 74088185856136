import React, { ErrorInfo, ReactNode, type JSX } from 'react';
import App, { AppInitialProps } from 'next/app';
import getConfig from 'next/config';
import { Router } from 'next/router';
import Head from 'next/head';
import { IntlProvider } from 'react-intl';
import ReactModal from 'react-modal';
import { StyleWrapper } from 'src/components';
import { UserProvider } from 'src/context/user';
import { memriseCaptureException } from 'src/utils/sentry';
import { isMemriseAppContext, LocaleProps, MemriseAppContext } from 'src/utils/context';
import { getCookies } from 'src/cookies';
import { getIp } from 'src/utils/html';
import { convertToMemriseError } from 'src/utils/errors';
import { redirect } from 'src/utils/location';
import { COOKIES_KEYS } from 'src/constants/cookies.const';
import { isClientSide } from 'src/utils/env/isClientSide';
import { isNotProduction } from 'src/utils/env/isNotProduction';
import { isStaging } from 'src/utils/env/isStaging';
import { isProduction } from 'src/utils/env/isProduction';
import ReactDOM from 'react-dom';
import { datadogRum } from '@datadog/browser-rum';
import { getLocaleMessages } from 'src/utils/getLocaleMessages';
import { intlErrorHandler, intlGetProviderLocale } from 'src/utils/intl';
import { NotificationProvider } from 'src/context/notification';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { newTracker } from '@snowplow/browser-tracker';
import { TimezonePlugin } from '@snowplow/browser-plugin-timezone';
import { GeolocationPlugin } from '@snowplow/browser-plugin-geolocation';
import { BrazeProvider } from 'src/context/braze';
import MemriseErrorPage from './_error';

const { publicRuntimeConfig } = getConfig();
const datadogAppId = publicRuntimeConfig.DATADOG_APPLICATION_ID;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Only refetch on mount and only if the data is sale, which it is by default
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      // Disable cache, enable in a case-by-case basis
      staleTime: 0,
      gcTime: 0,
      // We use fetch-retry to do retries on network errors at API level
      retry: false,
    },
  },
});

ReactModal.setAppElement('#__next');

declare global {
  interface Window {
    ReactIntlLocaleData: {
      [key: string]: string;
    };
  }
}

interface Props extends AppInitialProps, LocaleProps {
  serverErrorStatusCode?: number;
  serverErrorMessage?: string;
}

interface State {
  clientErrorStatusCode: number | null;
  clientErrorMessage: string | null;
  locale: string;
  messages: Record<string, string>;
}

export default class MemriseApp extends App<Props, State> {
  static async getInitialProps({ Component, ctx }: MemriseAppContext): Promise<Props> {
    let pageProps = {};

    // Check the ctx has cookies and extract the auth token if it does
    let authToken = '';
    /* istanbul ignore else: empty else branch */
    if (isMemriseAppContext(ctx)) {
      const { [COOKIES_KEYS.authToken]: gottenAuthToken } = getCookies(ctx.req);
      authToken = gottenAuthToken || '';
    }

    const { req } = ctx;
    const ip = req
      ? getIp(req)
      : /* istanbul ignore next: untested branch of code, please test */ null;

    const context = { ...ctx, authToken, ip };

    /* Dynamic rendering - request locale is set in our custom server.js */
    let locale = context.req?.locale;

    if (!locale) {
      /* Static rendering - for 404 and 500 errors, English only for now */
      locale = 'en';
      // eslint-disable-next-line no-console
      console.warn('Defaulting to English (for static rendering)');
    }
    const messages = await getLocaleMessages(locale);

    /* istanbul ignore else: untested branch of code, please test */
    if (Component) {
      /* istanbul ignore else: untested branch of code, please test */
      if (Component.getInitialProps) {
        try {
          pageProps = await Component.getInitialProps(context);
        } catch (e) {
          let statusCode = 500;

          const memriseError = convertToMemriseError(e as Error);
          /* istanbul ignore next: untested branch of code, please test */
          if (memriseError.httpStatusCode) {
            statusCode = memriseError.httpStatusCode;
          }

          /* istanbul ignore next: untested branch of code, please test */
          if (statusCode === 403) {
            const escapedRelativeUrl = encodeURIComponent(context.asPath || '');
            await redirect(`/signin?next=${escapedRelativeUrl}`, ctx);
          }

          /* istanbul ignore else: untested branch of code, please test */
          if (statusCode >= 500) {
            memriseCaptureException(e as Error, { ctx: context });
          }

          return {
            locale,
            messages,
            serverErrorStatusCode: statusCode,
            serverErrorMessage: memriseError.message,
            pageProps,
          };
        }
      }
    }
    return {
      locale,
      messages,
      pageProps,
    };
  }

  state = {
    clientErrorStatusCode: null,
    clientErrorMessage: null,
    locale: this.props.locale,
    messages: this.props.messages,
  };

  componentDidMount(): void {
    // Next Router doesnt scroll to the top of the page for client side transitions
    // https://github.com/zeit/next.js/issues/3249
    /* istanbul ignore next: cannot mock next/router here */
    Router.events.on('routeChangeComplete', () => {
      window.scrollTo(0, 0);
    });
  }

  // This catches errors that happen client side
  /* istanbul ignore next: untested branch of code, please test */
  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    let statusCode = 500;
    const memriseError = convertToMemriseError(error);

    if (memriseError.httpStatusCode) {
      statusCode = memriseError.httpStatusCode;
    }

    if (statusCode >= 500) {
      memriseCaptureException(error, { errorInfo });
    }

    this.setState({
      clientErrorStatusCode: statusCode,
      clientErrorMessage: memriseError.message,
    });
  }

  render(): JSX.Element {
    const { serverErrorStatusCode, serverErrorMessage, Component, pageProps } = this.props;

    // @ts-ignore
    const getLayout = Component.getLayout || ((page: ReactNode) => page);

    const { clientErrorStatusCode, clientErrorMessage, locale, messages } = this.state;

    if (isClientSide() && (isProduction() || isStaging())) {
      // Snowplow
      newTracker('web-client', 'https://events.memrise.com', {
        appId: `memrise-web-${publicRuntimeConfig.MEMRISE_ENV}`,
        platform: 'web',
        discoverRootDomain: true,
        contexts: {
          webPage: true,
          session: true,
          browser: false,
        },
        plugins: [GeolocationPlugin(), TimezonePlugin()],
      });
    }

    if (isClientSide() && isProduction() && datadogAppId) {
      datadogRum.init({
        allowedTracingUrls: [publicRuntimeConfig.MEMRISE_API_HOST],
        applicationId: datadogAppId,
        clientToken: publicRuntimeConfig.DATADOG_CLIENT_TOKEN,
        defaultPrivacyLevel: 'mask-user-input',
        env: publicRuntimeConfig.MEMRISE_ENV,
        sessionReplaySampleRate: 0,
        sessionSampleRate: 5,
        service: 'webclient',
        silentMultipleInit: true,
        site: 'datadoghq.com',
        trackUserInteractions: true,
        version: publicRuntimeConfig.GIT_COMMIT,
      });
    }

    if (isClientSide() && isNotProduction()) {
      // Require is used instead of import here as otherwise window is undefined and page will not load
      /* eslint-disable-next-line @typescript-eslint/no-require-imports */
      const axe = require('@axe-core/react');
      axe(React, ReactDOM, 1000);
    }

    return (
      <>
        <Head>
          <title>Memrise</title>
        </Head>
        <IntlProvider
          key={locale}
          locale={intlGetProviderLocale(locale)}
          messages={messages}
          onError={intlErrorHandler}
        >
          <StyleWrapper locale={locale}>
            <NotificationProvider>
              <QueryClientProvider client={queryClient}>
                <UserProvider locale={locale}>
                  <BrazeProvider>
                    {clientErrorStatusCode || serverErrorStatusCode ? (
                      <MemriseErrorPage
                        message={clientErrorMessage || serverErrorMessage}
                        onDismiss={
                          /* istanbul ignore next: untested branch of code, please test */ () => {
                            this.setState({
                              clientErrorStatusCode: undefined,
                              clientErrorMessage: undefined,
                            });
                          }
                        }
                        statusCode={clientErrorStatusCode || serverErrorStatusCode}
                      />
                    ) : (
                      /* istanbul ignore next: untested branch of code, please test */
                      getLayout(<Component {...pageProps} />, pageProps)
                    )}
                  </BrazeProvider>
                </UserProvider>
              </QueryClientProvider>
            </NotificationProvider>
          </StyleWrapper>
        </IntlProvider>
      </>
    );
  }
}
