import React, { ComponentType, FC } from 'react';
import * as S from './styles';
import { IconName } from '.';

type IconSet = { [key in IconName]: ComponentType };

export interface IconProps {
  name: IconName;
  className?: string;
  children?: never;
}

export const SvgHelperWrapper = ({
  Svg,
  ...rest
}: {
  Svg: ComponentType & { _defaultProps?: Record<string, unknown> };
}) => {
  // React 19 removed defaultProps for function components.
  // This component should be cleaned up in WEBSITE-6951.

  /* eslint-disable @typescript-eslint/no-explicit-any */
  if ((Svg as any).defaultProps) {
    Svg._defaultProps = (Svg as any).defaultProps;
    (Svg as any).defaultProps = undefined;
  }
  /* eslint-enable @typescript-eslint/no-explicit-any */

  return <Svg {...Svg._defaultProps} {...rest} />;
};

type IconHelper = (iconSet: IconSet) => FC<IconProps>;
const iconHelper: IconHelper = iconSet =>
  function Icon({ name, className }: IconProps) {
    const IconSVG: React.ComponentType = iconSet[name];
    return (
      <S.Root data-testid={name} className={className}>
        <SvgHelperWrapper Svg={IconSVG} />
      </S.Root>
    );
  };

export default iconHelper;
