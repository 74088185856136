import { DEFAULT_LOCALE, TRANSLATED_LOCALES } from 'src/constants/locales.const';

export const getLocaleMessages = async (locale: string): Promise<Record<string, string>> => {
  if (!TRANSLATED_LOCALES.includes(locale)) {
    throw new Error(`Unsupported locale: ${locale}`);
  }

  try {
    return (await import(`locales/${locale}.json`)).default;
  } catch (e) {
    return (await import(`locales/${DEFAULT_LOCALE}.json`)).default;
  }
};
