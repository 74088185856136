import aeroplane from './icons/aeroplane.svg';
import alertErrorFilled from './icons/alert-error_filled.svg';
import alertErrorOutline from './icons/alert-error_outline.svg';
import appleButtonIcon from './icons/apple-button-icon.svg';
import backwards from './icons/backwards.svg';
import bell from './icons/bell.svg';
import book from './icons/book.svg';
import buddy from './icons/buddy.svg';
import buddyFull from './icons/buddy-full.svg';
import buddyFullWaving from './icons/buddy-full-waving.svg';
import celebration from './icons/celebration.svg';
import check from './icons/check.svg';
import chevronLeft from './icons/chevron-left.svg';
import chevronRight from './icons/chevron-right.svg';
import circleFilled from './icons/circle-filled.svg';
import close from './icons/close.svg';
import closedFilled from './icons/closed_filled.svg';
import communicateFilled from './icons/communicate_filled.svg';
import communicateOutline from './icons/communicate_outline.svg';
import deleteRed from './icons/delete_red.svg';
import devTools from './icons/dev-tools.svg';
import difficultWordsFilled from './icons/difficult-words_filled.svg';
import difficultWordsOutline from './icons/difficult-words_outline.svg';
import displayLeft from './icons/display-left.svg';
import displayRight from './icons/display-right.svg';
import down from './icons/down.svg';
import edit from './icons/edit.svg';
import facebookShareIcon from './icons/facebook-share-icon.svg';
import fire from './icons/fire.svg';
import fireDisabled from './icons/fire_disabled.svg';
import flag from './icons/flag.svg';
import forward from './icons/forward.svg';
import glassesFilled from './icons/glasses_filled.svg';
import glassesOutline from './icons/glasses_outline.svg';
import headphones from './icons/headphones.svg';
import hint from './icons/hint.svg';
import hintHovered from './icons/hint-hovered.svg';
import hintSelected from './icons/hint-selected.svg';
import homeFilled from './icons/home_filled.svg';
import homeOutline from './icons/home_outline.svg';
import idea from './icons/idea.svg';
import info from './icons/info.svg';
import infoOutline from './icons/info-outline.svg';
import keyboard from './icons/keyboard.svg';
import learnFilled from './icons/learn_filled.svg';
import learnOutline from './icons/learn_outline.svg';
import lightningBoltInCircle from './icons/lightning_bolt_circle.svg';
import likeFilled from './icons/like_filled.svg';
import likeOutline from './icons/like_outline.svg';
import memrise from './icons/memrise.svg';
import memriseSmallBlack from './icons/memrise-small-black.svg';
import microphoneOutline from './icons/microphone_outline.svg';
import microphoneFilled from './icons/microphone_filled.svg';
import more from './icons/more.svg';
import noStreak from './icons/no-streak.svg';
import padlock from './icons/padlock.svg';
import padlockGradient from './icons/padlock-gradient.svg';
import padlockShadow from './icons/padlock-shadow.svg';
import pause from './icons/pause.svg';
import playFilled from './icons/play_filled.svg';
import playVideo from './icons/play_video.svg';
import plus from './icons/plus.svg';
import practice from './icons/practice.svg';
import premiumStar from './icons/premium-star.svg';
import premiumStarNoFill from './icons/premium-star-no-fill.svg';
import profileIcon from './icons/profile-icon.svg';
import question from './icons/question.svg';
import replay from './icons/replay.svg';
import replayThin from './icons/replay_thin.svg';
import search from './icons/search.svg';
import sendFilled from './icons/send_filled.svg';
import settingsFilled from './icons/settings_filled.svg';
import shoot03 from './icons/shoot03.svg';
import social from './icons/social.svg';
import sort from './icons/sort.svg';
import sortZa from './icons/sort-za.svg';
import sortAz from './icons/sort-az.svg';
import soundFull from './icons/sound_full.svg';
import soundHalf from './icons/sound_half.svg';
import soundMuted from './icons/sound_muted.svg';
import speakerVolume3 from './icons/speaker-volume3.svg';
import speedReviewOutlined from './icons/speed-review_outlined.svg';
import speedReviewFilled from './icons/speed-review_filled.svg';
import stars from './icons/stars.svg';
import starsCompleted from './icons/starsCompleted.svg';
import statistics from './icons/statistics.svg';
import streak from './icons/streak.svg';
import subtitles from './icons/subtitles.svg';
import tickFilled from './icons/tick_filled.svg';
import tickOutline from './icons/tick_outline.svg';
import translate from './icons/translate.svg';
import triangle from './icons/triangle.svg';
import up from './icons/up.svg';
import videoLibraryFilled from './icons/video-library_filled.svg';
import videoLibraryOutline from './icons/video-library_outline.svg';
import warning from './icons/warning.svg';
import wave from './icons/wave.svg';
import sentencesOutline from './icons/sentences_outline.svg';

export default {
  aeroplane,
  alertErrorFilled,
  alertErrorOutline,
  appleButtonIcon,
  backwards,
  bell,
  book,
  buddy,
  buddyFull,
  buddyFullWaving,
  celebration,
  check,
  chevronLeft,
  chevronRight,
  circleFilled,
  close,
  closedFilled,
  communicateFilled,
  communicateOutline,
  deleteRed,
  devTools,
  difficultWordsFilled,
  difficultWordsOutline,
  displayLeft,
  displayRight,
  down,
  edit,
  facebookShareIcon,
  fire,
  fireDisabled,
  flag,
  forward,
  glassesFilled,
  glassesOutline,
  headphones,
  hint,
  hintHovered,
  hintSelected,
  homeFilled,
  homeOutline,
  idea,
  info,
  infoOutline,
  keyboard,
  learnFilled,
  learnOutline,
  lightningBoltInCircle,
  likeFilled,
  likeOutline,
  memrise,
  memriseSmallBlack,
  microphoneFilled,
  microphoneOutline,
  more,
  noStreak,
  padlock,
  padlockGradient,
  padlockShadow,
  pause,
  playFilled,
  playVideo,
  plus,
  practice,
  premiumStar,
  premiumStarNoFill,
  profileIcon,
  question,
  replay,
  replayThin,
  search,
  sendFilled,
  sentencesOutline,
  settingsFilled,
  shoot03,
  social,
  sort,
  sortZa,
  sortAz,
  soundFull,
  soundHalf,
  soundMuted,
  speakerVolume3,
  speedReviewFilled,
  speedReviewOutlined,
  stars,
  starsCompleted,
  statistics,
  streak,
  subtitles,
  tickFilled,
  tickOutline,
  translate,
  triangle,
  up,
  videoLibraryFilled,
  videoLibraryOutline,
  warning,
  wave,
};
