import { toCamelCaseDeep } from 'src/utils/transform';
import MemriseApi from '../MemriseApi';
import * as Schema from './schema';
import * as Types from './types';

export default class LearnableDetailsClass extends MemriseApi {
  constructor() {
    super({ endpointPrefix: '/learnable_details' });
  }

  /**
   * Get learnable details by ID
   *
   * @param learnableIdKey - The ID key of the learnable
   * @returns A promise that resolves to a LearnableDetails object
   */
  public async getLearnableDetails(learnableIdKey: number): Promise<Types.LearnableDetails> {
    const response = await this.get<Schema.LearnableDetailsResponse>(`/${learnableIdKey}/`);

    return toCamelCaseDeep(response);
  }
}

export const LearnableDetailsApi = new LearnableDetailsClass();
