import MemriseApi from '../MemriseApi';

class DevToolsApiClass extends MemriseApi {
  constructor() {
    super({ endpointPrefix: '/dev_tools' });
  }

  /**
   * Toggles a dev user's subscription between free and premium
   */
  public async toggleUserSubscription() {
    return this.post('/user_subscription/', {});
  }

  public async simulateLearnDailyStatistics({
    languagePairId,
    numItems,
  }: {
    languagePairId: number;
    numItems: number;
  }) {
    return this.post(`/learn/${languagePairId}/`, {
      bodyObject: {
        numItems,
      },
    });
  }

  public async simulateReviewDailyStatistics({
    languagePairId,
    numItems,
  }: {
    languagePairId: number;
    numItems: number;
  }) {
    return this.post(`/review/${languagePairId}/`, {
      bodyObject: {
        numItems,
      },
    });
  }

  public async simulateReviewDifficultDailyStatistics({
    languagePairId,
    numItems,
  }: {
    languagePairId: number;
    numItems: number;
  }) {
    return this.post(`/review_difficult/${languagePairId}/`, {
      bodyObject: {
        numItems,
      },
    });
  }

  public async simulateSetForReviewDailyStatistics({
    languagePairId,
    numItems,
  }: {
    languagePairId: number;
    numItems: number | null;
  }) {
    return this.post(`/set_for_review/${languagePairId}/`, {
      bodyObject: {
        numItems,
      },
    });
  }
}

export const DevToolsApi = new DevToolsApiClass();
