import { toCamelCaseDeep } from 'src/utils/transform';
import { ApiTargetItemType } from 'src/api/learningSessions/types';
import MemriseApi from '../MemriseApi';
import * as Schema from './schema';
import * as Types from './types';

export default class UserDictionaryClass extends MemriseApi {
  constructor() {
    super({ endpointPrefix: '/me' });
  }

  public async getAllUserLearnables(
    languagePairId: number,
    params: Schema.UserLearnablesRequest,
  ): Promise<Types.UserLearnablesResponse> {
    const response = await this.get<Schema.UserLearnablesResponse, Schema.UserLearnablesRequest>(
      `/language_pairs/${languagePairId}/learnables/`,
      params,
    );
    return toCamelCaseDeep(response);
  }

  public async updateSingleLearnable(
    languagePairId: number,
    learnableIdKey: number,
    learnable: Schema.SingleLearnableStat,
  ) {
    await this.put(`/language_pairs/${languagePairId}/learnables/${learnableIdKey}/`, {
      bodyObject: learnable,
    });
  }

  public async updateLearnables(languagePairId: number, learnables: Schema.LearnablesStat) {
    await this.put(`/language_pairs/${languagePairId}/learnables/`, {
      bodyObject: learnables,
    });
  }

  public async getRecentLearnables(
    languagePairId: number,
    typesToExclude: ApiTargetItemType[] = [],
  ): Promise<Types.RecentLearnablesResponse> {
    const response = await this.get<
      Schema.RecentLearnablesResponse,
      Schema.RecentLearnablesRequest
    >(`/language_pairs/${languagePairId}/recent_learnables/`, {
      types_to_exclude: typesToExclude,
    });
    return toCamelCaseDeep(response);
  }

  public async getLearnablesPronunciationData(
    languagePairId: number,
    learnableIds: number[],
  ): Promise<Types.LearnablePronunciationData[]> {
    const response = await this.get<Schema.LearnablesPronunciationDataResponse>(
      `/language_pairs/${languagePairId}/learnables/${learnableIds.join(',')}/pronunciation_data/`,
    );

    const initialPositionComparator = (
      a: Types.LearnablePronunciationData,
      b: Types.LearnablePronunciationData,
    ) => learnableIds.indexOf(a.learnableIdKey) - learnableIds.indexOf(b.learnableIdKey);

    return toCamelCaseDeep(response.learnables).sort(initialPositionComparator);
  }

  public async sendLearnablePronunciation(
    languagePairId: number,
    learnableId: number,
    wavAudioBlob: Blob,
  ): Promise<Types.LearnablePronunciationResponse> {
    // the name of the file is not important as long as it uses .wav extension
    const fileName = 'recording.wav';
    const formData = new FormData();
    formData.append('file', wavAudioBlob, fileName);

    const response = await this.postRawData<Schema.LearnablePronunciationResponse>(
      `/language_pairs/${languagePairId}/learnables/${learnableId}/pronunciation/`,
      { body: formData },
    );

    return toCamelCaseDeep(response);
  }
}

export const UserDictionaryApi = new UserDictionaryClass();
