import { ErrorInfo } from 'react';
import getConfig from 'next/config';
import * as Sentry from '@sentry/node';
import { getCurrentScope } from '@sentry/browser';
import { MemrisePageContext } from 'src/utils/context';

const { publicRuntimeConfig } = getConfig();

/* istanbul ignore next: untested branch of code, please test */
if (publicRuntimeConfig.SENTRY_DSN !== 'disabled') {
  /* istanbul ignore next */
  Sentry.init({
    dsn: publicRuntimeConfig.SENTRY_DSN,
    environment: publicRuntimeConfig.SENTRY_ENVIRONMENT,
    integrations: [
      Sentry.captureConsoleIntegration({
        // Track any of the following console outputs in Sentry
        // ['log', info'', 'warn', 'error', 'debug', 'assert']
        levels: ['error', 'assert'],
        handled: false,
      }),
    ],
    maxBreadcrumbs: 50,
    attachStacktrace: true,
    sendDefaultPii: true,
    release: publicRuntimeConfig.GIT_COMMIT,
    /* These errors send lots of times per user
     *
     * MEMRISE is a global js object used on webapp frontend, this is likely user scripts
     * https://errors.m2r3.co/memrise/webclient/issues/76991/
     *
     * Intl is not defined on IE11 and discontinued phones
     * https://errors.m2r3.co/memrise/webclient/issues/82736/
     *
     * adsbygoogle.push() error is handled but still triggers Sentry’s window.onerror handler
     * https://sentry.io/organizations/memrise/issues/2799461905/?project=5891339&referrer=slack
     *
     * 401 (UNAUTHORISED) error calling web API happens when a user logs out in another tab or frequently clears cookies
     * https://sentry.io/organizations/memrise/issues/2839985936/?project=5891339&referrer=slack
     *
     * no_ins - Google adverts error that started happening, and doesn't seem to reduce our revenue much
     * https://sentry.io/organizations/memrise/issues/3513334876/?project=5891339
     *
     * [analytics.js] Failed to load Analytics.js - groups various load errors that can't be handled
     * https://sentry.io/organizations/memrise/issues/3661670930/?referrer=slack
     *
     * undefined (exact match) - we should ignore all errors with an undefined error message as
     * they don't give us any information in order to fix it
     *
     * Failed to lookup route - happens everytime Classic dashboard makes a <link> to a webapp page
     * (if we remove Classic can get rid of that ignore)
     *
     * Play error - happens on the MemBot, we can't really do something with it (costs money)
     */
    ignoreErrors: [
      'MEMRISE is not defined',
      'ReferenceError: Intl is not defined',
      'adsbygoogle.push() error: No slot size for availableWidth',
      '401 (UNAUTHORISED) error calling web API',
      'UNAUTHORISED',
      /^no_ins$/,
      '[analytics.js] Failed to load Analytics.js',
      /^undefined$/,
      'Failed to lookup route',
      'Play error',
    ],
  });
}

interface NextError extends Error {
  statusCode?: number;
}

export const memriseCaptureException = (
  err: NextError,
  params: { errorInfo?: ErrorInfo; ctx?: MemrisePageContext },
): void => {
  const { errorInfo, ctx }: { errorInfo?: ErrorInfo; ctx?: MemrisePageContext } = params;

  // We don't want to log 4XX events to sentry
  if (err.statusCode && err.statusCode < 500) {
    return;
  }

  const scope = getCurrentScope();
  if (err.message) {
    // De-duplication currently doesn't work correctly for SSR / browser errors
    // so we force deduplication by error message if it is present
    scope.setFingerprint([err.message]);
  }

  if (err.statusCode) {
    scope.setExtra('statusCode', err.statusCode);
  }

  if (typeof window === 'undefined') {
    scope.setTag('side', 'server');
  } else {
    scope.setTag('side', 'client');
  }

  if (ctx) {
    const { req, res, query, pathname } = ctx;
    if (req) {
      scope.setExtra('url', req.url);
      scope.setExtra('method', req.method);
      scope.setExtra('headers', req.headers);
    } else {
      scope.setExtra('query', query);
      scope.setExtra('pathname', pathname);
    }
    if (res && res.statusCode) {
      scope.setExtra('statusCode', res.statusCode);
    }
  }

  if (errorInfo) {
    scope.setExtra('componentStack', errorInfo.componentStack);
  }

  Sentry.captureException(err);
};
