// This file has .js extension to allow its usage in server.js
// Import from locales.const.ts inside the TypeScript code

// All locales for what we have translation files in locales/ folder
const TRANSLATED_LOCALES = [
  'ar',
  'de',
  'en',
  'es',
  'es-mx',
  'fr',
  'hi',
  'id',
  'it',
  'ja',
  'ko',
  'nl',
  'no',
  'pl',
  'pt',
  'pt-br',
  'ru',
  'sv',
  'tr',
  'vi',
  'zh-cn',
  'zh-hant',
];

// We don't have pt source language right now and that is why don't support it inside the app, but still support it for unauthorised pages
const SUPPORTED_PUBLIC_LOCALES = TRANSLATED_LOCALES;
const SUPPORTED_APP_LOCALES = TRANSLATED_LOCALES.filter(l => l !== 'pt');

// One of SUPPORTED_APP_LOCALES that is used as default
const DEFAULT_LOCALE = 'en';

const CUSTOM_PUBLIC_LOCALES_MAP = {
  'en-us': 'en',
  'zh-tw': 'zh-hant',
  'zh-hk': 'zh-hant',
  'zh-sg': 'zh-cn',
  zh: 'zh-cn',
  'es-419': 'es-MX',
};

const CUSTOM_APP_LOCALES_MAP = {
  ...CUSTOM_PUBLIC_LOCALES_MAP,
  pt: 'pt-br', // we support only pr-br inside the app
};

module.exports = {
  TRANSLATED_LOCALES,
  SUPPORTED_PUBLIC_LOCALES,
  SUPPORTED_APP_LOCALES,
  DEFAULT_LOCALE,
  CUSTOM_PUBLIC_LOCALES_MAP,
  CUSTOM_APP_LOCALES_MAP,
};
