export const ROUTES = {
  ai: {
    buddies: '/buddies',
    dialog: '/buddy',
  },
  aprender: {
    difficult: '/aprender/difficult',
    learn: '/aprender/learn',
    review: '/aprender/review',
    speed: '/aprender/speed',
  },
  bienvenue: '/bienvenue',
  dashboard: '/dashboard',
  experiments: {
    feature: '/feature',
  },
  languages: '/languages',
  membot: {
    dialog: '/membot',
    select: '/communicate',
  },
  onboarding: {
    choosePlan: '/choose-plan',
    selectLevel: '/select-level',
  },
  payment: {
    confirmation: `/payment/confirmation`,
    plans: '/payment/plans',
  },
  progress: '/progress',
  scenarios: {
    learn: '/learn',
    practice: '/practice',
  },
  settings: {
    learning: '/learning-settings',
    user: '/settings/',
  },
  signIn: '/signin',
  signOut: '/signout',
  video: {
    immerse: '/immerse',
    media: '/media',
  },
  myActivities: '/my-activities',
  dictionary: '/user-dictionary',
} as const;
