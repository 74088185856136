import {
  SUPPORTED_PUBLIC_LOCALES,
  SUPPORTED_APP_LOCALES,
  CUSTOM_PUBLIC_LOCALES_MAP as _CUSTOM_PUBLIC_LOCALES_MAP,
  CUSTOM_APP_LOCALES_MAP as _CUSTOM_APP_LOCALES_MAP,
} from './serverLocales.const';

export {
  TRANSLATED_LOCALES,
  SUPPORTED_PUBLIC_LOCALES,
  SUPPORTED_APP_LOCALES,
  DEFAULT_LOCALE,
} from './serverLocales.const';

export const CUSTOM_PUBLIC_LOCALES_MAP: Record<string, string | undefined> =
  _CUSTOM_PUBLIC_LOCALES_MAP;
export const CUSTOM_APP_LOCALES_MAP: Record<string, string | undefined> = _CUSTOM_APP_LOCALES_MAP;

export const SOURCE_LANGUAGES_LOCALES_MAP: Record<string, string> = {
  // All supported app locales except of zh-hant are mapped to source languages 1 to 1
  ...SUPPORTED_APP_LOCALES.filter(l => l !== 'zh-hant').reduce(
    (res, locale) => ({
      ...res,
      [locale]: locale,
    }),
    {},
  ),

  'zh-tw': 'zh-hant',
  'en-us': 'en',
};

// All locales that could be used as suffixes for public pages
// CUSTOM_PUBLIC_LOCALES_MAP should contain the mappings for all locales that are outside TRANSLATED_LOCALES list
export const SUPPORTED_PUBLIC_LOCALE_SUFFIXES = [
  ...SUPPORTED_PUBLIC_LOCALES,
  'en-us',
  'zh-tw',
  'zh-hk',
  'zh-sg',
];
